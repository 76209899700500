import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../constants';
//template async "thunk" or "action"

export const fetchExamById = createAsyncThunk(
  'exams/fetchExamById',
  async (examId, thunkAPI) => {
    const response = await axios.get(`${API_URL}/getall/${examId}`);
    console.log(response.data, 'response.data');
    return response.data;
  }
);

const initialState = {
  ExamID: 1,
  ExamDate: Date.now(),
  ExamPassingGradeThreshhold: 80,
  ExamAttempt: 1,
  ExamGrade: 0,
  ExamTimeAllowed: 50,
  ExamDateBarrier: 7,
  examquestions: [],
  sections: [],
  error: null,
  loading: false,
  examPopulated: false,
};

const examSlice = createSlice({
  name: 'exam',
  initialState,
  reducers: {
    setSelectedAnswerForQuestion: (state, action) => {
      const questionToUpdate = state.examquestions.find(
        q => q.ExamQuestionID === action.payload.ExamQuestionID
      );

      const index = state.examquestions.indexOf(questionToUpdate);

      state.examquestions[index] = {
        ...state.examquestions[index],
        selectedAnswer: action.payload,
      };
    },
    clearExam: () => {
      return initialState;
      // state.examquestions = [];
      // state.examPopulated = false;
      // state.error = null;
      // state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExamById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExamById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // ExamTimeAllowed: 30,

        state.ExamID = parseInt(action.payload.ExamID);
        state.ExamPassingGradeThreshhold = parseFloat(
          action.payload.ExamPassingGradeThreshold
        );
        state.ExamTimeAllowed = parseInt(action.payload.ExamTimeAllowed);
        state.sections = action.payload.sections;
        state.examquestions = getShuffledArr(action.payload.examquestions);
        state.ExamDateBarrier = action.payload.ExamDateBarrier;
        state.examPopulated = true;
      })
      .addCase(fetchExamById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

//shuffle exam questions so no test is the same
function getShuffledArr(arr) {
  if (arr.length === 1) {
    return arr;
  }
  const rand = Math.floor(Math.random() * arr.length);
  return [arr[rand], ...getShuffledArr(arr.filter((_, i) => i !== rand))];
}

export const { setExam, setSelectedAnswerForQuestion, clearExam } =
  examSlice.actions;

export default examSlice.reducer;
