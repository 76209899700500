import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { TextField, Checkbox, Button, FormGroup, InputLabel, Grid, Modal, Select, Box, MenuItem } from "@mui/material"; 
import {API_URL} from "../../constants";

import SectionCreation from "./SectionCreation"

export default function ExamQuestionEntry() {
    const navigate = useNavigate();
    const location = useLocation();
    const ExamID = location.state.Exam.ExamID;
    //state variables for objects
    const [ExamQuestion, setExamQuestion] = useState({});
    const [ExamAnswerA, setExamAnswerA] = useState({});
    const [ExamAnswerB, setExamAnswerB] = useState({});
    const [ExamAnswerC, setExamAnswerC] = useState({});
    const [ExamAnswerD, setExamAnswerD] = useState({});

    const [Section, setSection] = useState(0);
    const [SectionName, setSectionName] = useState("");
    const [SectionScore, setSectionScore] = useState(0);
    const [Sections, setSections] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //check cert number against number in db
    const examQuestionCreation = (e) => {
        e.preventDefault();

        //add sectionid to posts
        
        axios.all([
            axios.post(`${API_URL}/addquestion/${ExamID}`, {ExamQuestion, Section}),
            axios.post(`${API_URL}/addanswer/${ExamID}`, {ExamAnswerA}),
            axios.post(`${API_URL}/addanswer/${ExamID}`, {ExamAnswerB}),
            axios.post(`${API_URL}/addanswer/${ExamID}`, {ExamAnswerC}),
            axios.post(`${API_URL}/addanswer/${ExamID}`, {ExamAnswerD})
        ]).then(axios.spread((question, ansa, ansb, ansc, ansd)=> {
            console.log(question, ansa, ansb, ansc, ansd);
        })).catch(e=> {
            console.log(e);
        })
               
        navigate('/exam-question-list', {  state: {ExamID}});
    }

    useEffect(()=> {
        console.log("State carried over from other page: ", location.state.Exam);
        axios.get(`${API_URL}/getsections/${ExamID}`).then((res)=> {
            setSections(res.data);
            setOpen(false);
        }); 
    },[])

    

    return(
        <div className="container" style={{paddingTop:125, paddingRight: 200, paddingLeft:200}}>
            <Button onClick={handleOpen}>Add Section</Button>
            <div className="modal" style={{backgroundColor:'white'}}>
                <Modal open={open} onClose={handleClose}>
                    <Box>
                       <SectionCreation/>
                    </Box>
                </Modal>
            </div>
           <form onSubmit={examQuestionCreation}>
           <Grid container spacing="2" paddingX={2}>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Section:</InputLabel>
                        <Select onChange={e => setSection(e.target.value)}>
                            {
                               Sections?.map((sec)=>{
                                return <MenuItem value={sec.SectionID}>{sec.Name}</MenuItem>
                               })
                            }
                        </Select>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Question:</InputLabel>
                        <TextField rows={4} name="Question" onChange={e => setExamQuestion(e.target.value)} variant="outlined" placeholder="Enter Question"/>
                        </FormGroup>
                    </Grid>

                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>A:</InputLabel>
                        <TextField rows={2} name="A" onChange={e => setExamAnswerA(e.target.value)} variant="outlined" placeholder="Enter Answer Choice A"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>B:</InputLabel>
                        <TextField rows={2} name="B" onChange={e => setExamAnswerB(e.target.value)} variant="outlined" placeholder="Enter Answer Choice B"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>C:</InputLabel>
                        <TextField rows={2} name="C" onChange={e => setExamAnswerC(e.target.value)} variant="outlined" placeholder="Enter Answer Choice C"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>D:</InputLabel>
                        <TextField rows={2} name="D" onChange={e => setExamAnswerD(e.target.value)} variant="outlined" placeholder="Enter Answer Choice D"/>
                        </FormGroup>
                    </Grid>

                    
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                        <Button type="submit" onClick={examQuestionCreation}>Continue</Button>
                    </Grid>
            </Grid>
           </form>
        </div>
    )
}