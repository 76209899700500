import React from 'react';
import { useState, useEffect } from 'react';

const Timer = ({ examtimeallowed, timeUp, isSubmitted }) => {
  let initialMinute;
  let initialSeconds;
  if (examtimeallowed < 1) {
    initialMinute = 0;
    initialSeconds = Math.ceil(examtimeallowed * 60);
  } else {
    initialMinute = Math.floor(examtimeallowed);
    initialSeconds = Math.ceil((examtimeallowed - initialMinute) * 60);
  }

  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    if (minutes <= 0 && seconds === 0) {
      timeUp();
    }

    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className='timer-text'>
      {!isSubmitted ? (
        minutes === 0 && seconds === 0 ? (
          'Time is Up!'
        ) : (
          <h1>
            {' '}
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </h1>
        )
      ) : (
        <h1>COMPLETED</h1>
      )}
    </div>
  );
};

export default Timer;
