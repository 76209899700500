
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userSlice from "./features/user/userSlice";
import examSlice from './features/exam/examSlice';


const rootReducer = combineReducers({
  user: userSlice,
  exam: examSlice,
});

const persistConfig = {
  key: "ERA",
  storage,
  whitelist: ["user", "exam"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
