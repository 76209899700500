import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { TextField, Button, FormGroup, InputLabel, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../features/user/userSlice";

export default function FrontPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const verified = useSelector((state) => state.user.isLoggedIn);
  function submitValues(e) {
    e.preventDefault();
    let data = {
      Email,
      Password,
    };
    dispatch(fetchUser(data));
  }
  useEffect(() => {
    if (verified === true) {
      navigate("/examentry");
    }
  }, [verified]);

  return (
    <div className="container" style={{ paddingTop: 75 }}>
      <h1 style={{ color: "black", paddingLeft: 15 }}>Login</h1>
      <form onSubmit={submitValues}>
        <Grid container spacing="2" paddingX={2}>
          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <FormGroup>
              <InputLabel>Email:</InputLabel>
              <TextField
                name="Email"
                placeholder="Enter Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
          </Grid>
          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <FormGroup>
              <InputLabel>Password:</InputLabel>
              <TextField
                type="password"
                name="Password"
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
          </Grid>
          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <Button type="submit" onClick={submitValues}>
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
