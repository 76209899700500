import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import {
  TextField,
  Checkbox,
  Button,
  FormGroup,
  InputLabel,
  Grid,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { API_URL } from '../../constants';

export default function ExamCreation() {
  const navigate = useNavigate();
  //state variables for objects
  const [Exam, setExam] = useState({
    ExamName: '',
    ExamDescription: '',
    ExamDateBarrier: 0,
    ExamPassingGradeThreshold: 0.0,
    ExamDate: '',
  });

  //check cert number against number in db
  const examCreation = e => {
    e.preventDefault();
    console.log('Exam Properties Before Post: ', Exam);
    axios
      .post(`${API_URL}/exam`, { Exam })
      .then(res => {
        console.log(res.data);
        navigate('/exam-question-creation', { state: { Exam } });
      })
      .catch(ex => {
        console.log(ex);
      });
  };

  const handleFieldChange = e => {
    let name = e.target.name;
    setExam({
      ...Exam,
      [name]: e.target.value,
    });
  };

  const handleDateChange = e => {
    setExam({
      ...Exam,
      ['ExamDate']: e.$d,
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className='container'
        style={{ paddingTop: 125, paddingRight: 200, paddingLeft: 200 }}
      >
        <form onSubmit={examCreation}>
          <Grid container spacing='2' paddingX={2}>
            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <FormGroup>
                <InputLabel>Exam Name:</InputLabel>
                <TextField
                  name='ExamName'
                  onChange={e => handleFieldChange(e)}
                  variant='outlined'
                  placeholder='Enter Exam Title'
                />
              </FormGroup>
            </Grid>
            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <FormGroup>
                <InputLabel>Exam Description:</InputLabel>
                <TextField
                  name='ExamDescription'
                  onChange={e => handleFieldChange(e)}
                  variant='outlined'
                  placeholder='Enter Exam Description'
                />
              </FormGroup>
            </Grid>

            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <FormGroup>
                <InputLabel>Date Barrier:</InputLabel>
                <TextField
                  name='ExamDateBarrier'
                  onChange={e => handleFieldChange(e)}
                  variant='outlined'
                  placeholder='Enter Date Barrier'
                />
              </FormGroup>
            </Grid>

            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <FormGroup>
                <InputLabel>Passing Grade Threshold:</InputLabel>
                <TextField
                  name='ExamPassingGradeThreshold'
                  type='number'
                  onChange={e => handleFieldChange(e)}
                  variant='outlined'
                  placeholder='Enter Passing Grade Threshold'
                />
              </FormGroup>
            </Grid>
            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <FormGroup>
                <InputLabel>Date:</InputLabel>
                <DatePicker
                  format='MM/DD/YYYY'
                  name='ExamDate'
                  onChange={e => handleDateChange(e)}
                ></DatePicker>
              </FormGroup>
            </Grid>

            <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
              <Button type='submit' onClick={examCreation}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </LocalizationProvider>
  );
}
