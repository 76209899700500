import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import {
  TextField,
  Checkbox,
  Button,
  FormGroup,
  InputLabel,
  Grid,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { API_URL } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkExamCode, clearExamVerified, examCodeResponse } from '../features/user/userSlice';
import { fetchExamById } from '../features/exam/examSlice';

export default function FrontPage() {
  const [open, setOpen] = useState(false);
  const [certID, setCertID] = useState('');
  const [examInformation, setExamInformation] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState('')
  const examVerified = useSelector(state => state.user.examVerified);
  const exams = useSelector(state => state.user.exams);
  const examPopulated = useSelector(state => state.exam.examPopulated);
  const checkCertValidity = e => {
    e.preventDefault();
    console.log('Cert ID:', certID);
    setMessage("In order to proceed please contact support@coitb.org to setup a proctor time.");
    //dispatch(checkExamCode(certID));
  };
  useEffect(() => {
    if (examVerified === true) {
      //handleOpen();
    }
  }, [examVerified]);

  return (
    <div
      className='container'
      style={{ paddingTop: 125, paddingRight: 200, paddingLeft: 200 }}
    >
      <form onSubmit={checkCertValidity}>
        <Grid container spacing='2' paddingX={2}>
          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <FormGroup>
              <InputLabel>Exam Code:</InputLabel>
              <TextField
                name='CertID'
                onChange={e => setCertID(e.target.value)}
                variant='outlined'
                placeholder='Enter Exam Code'
              />
            </FormGroup>
          </Grid>

          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <FormGroup>
              <InputLabel>{message}</InputLabel>
              </FormGroup>
          </Grid>  

          <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
            <Button type='submit' onClick={checkCertValidity}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#ffffff',
            border: '2px solid rgba(0,0,0,0.14)',
            boxShadow:
              '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);',
            color: 'black',
            p: 4,
          }}
        >
          <Typography
            variant='h4'
            style={{
              textAlign: 'center',
              marginBottom: '5%',
              textDecoration: 'underline',
            }}
          >
            Verify Course
          </Typography>
          <Typography
            variant='h6'
            sx={{
              textAlign: 'center',
              marginBottom: '5%',
            }}
          >
            Course Title: {exams ? exams.ExamName : ''}
          </Typography>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                dispatch(fetchExamById(exams.ExamID));
                navigate('/exam');
                handleClose();
              }}
            >
              Start Exam
            </Button>
            <Button
              onClick={() => {
                dispatch(clearExamVerified());
                handleClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

// e.preventDefault();
// axios.post(`${API_URL}/checkcert`, {certID}).then(res=> {
//     console.log(res.data);
//     navigate('/exam', {  state: {certID}});
// }).catch(ex => {
//     console.log(ex);
// })
