import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { TextField, Checkbox, Button, FormGroup, InputLabel, Grid, } from "@mui/material"; 
import { DataGrid } from "@mui/x-data-grid";
import {API_URL} from "./constants";

export default function SectionsList() {
    const navigate = useNavigate();
    const location = useLocation();
    //state variables for objects
    const [sections, setSections] = useState({});

    useEffect(()=> {
       axios.get(`${API_URL}/getsections/${location.state.ExamID}`).then((res)=> {
            setSections(res.data);
       });
    },[])

    const columns = [
        { field: 'SectionID', headerName: 'Section ID', width: 150 },
        { field: 'Name', headerName: 'Name', width: 150 },
        { field: 'Score', headerName:'Score', width: 150},

      ];

      //onRowClick={(rows)=> {navigate('/exam-question-creation', state: {rows.ExamQuestionID}) }}
    

    return(
        <div className="container" style={{paddingTop:125, paddingRight: 200, paddingLeft:200}}>
            <DataGrid getRowId={(row)=> row.SectionID} rows={sections} columns={columns} />
     </div>
    )
}