import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkmark } from 'react-checkmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faSpinner,
  faBrain,
} from '@fortawesome/free-solid-svg-icons';

const QuestionBubble = ({
  index,
  answered,
  questionNumber,
  current,
  skipped,
  onClick,
  disabled,
}) => {
  if (current)
    return (
      <button disabled={disabled} className='questionBubble'>
        {questionNumber}
        <FontAwesomeIcon icon={faBrain} color='pink' />
      </button>
    );

  if (answered)
    return (
      <button disabled={disabled} className='questionBubble' onClick={onClick}>
        {questionNumber}
        <Checkmark size='small' />
      </button>
    );

  if (skipped)
    return (
      <button disabled={disabled} className='questionBubble' onClick={onClick}>
        {questionNumber}
        <FontAwesomeIcon icon={faExclamationCircle} color='red' />
      </button>
    );

  return (
    <button disabled={disabled} className='questionBubble' onClick={onClick}>
      {questionNumber}
      <FontAwesomeIcon icon={faSpinner} color='yellow' />
    </button>
  );
};

export default QuestionBubble;
