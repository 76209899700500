import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { API_URL } from './constants';

export default function ExamsList() {
  const navigate = useNavigate();
  const location = useLocation();
  //state variables for objects
  const [exams, setExams] = useState([]);

  useEffect(() => {
    axios
      .get(`${API_URL}/examlist`)
      .then(res => {
        console.log('Exams List Data: ', res.data);
        setExams(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const columns = [
    { field: 'ExamID', headerName: 'Exam ID', width: 150 },
    { field: 'ExamName', headerName: 'Name', width: 150 },
    { field: 'ExamDescription', headerName: 'Description', width: 150 },
    { field: 'ExamDateBarrier', headerName: 'Date Barrier', width: 150 },
    {
      field: 'ExamPassingGradeThreshold',
      headerName: 'Grade Threshold',
      width: 150,
    },
  ];

  //onRowClick={(rows)=> {navigate(`/exam-question-list`, state: {rows.ExamID}) }}

  return (
    <div
      className='container'
      style={{ paddingTop: 125, paddingRight: 200, paddingLeft: 200 }}
    >
      <DataGrid rows={exams} columns={columns} getRowId={row => row.ExamID} />
    </div>
  );
}
