import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { TextField, Checkbox, Button, FormGroup, InputLabel, Grid, } from "@mui/material"; 
import { DataGrid } from "@mui/x-data-grid";
import {API_URL} from "./constants";

export default function QuestionsList() {
    const navigate = useNavigate();
    const location = useLocation();
    //state variables for objects
    const [questions, setQuestions] = useState({});

    useEffect(()=> {
        axios.get(`${API_URL}/getQuestionsAnswersSections`, location.state.ExamID).then((res)=> {
            setQuestions(res.data);
        });    
    },[])

    const columns = [
        { field: 'ExamQuestionID', headerName: 'Question ID', width: 150 },
        { field: 'SectionID', headerName: 'Section', width: 150 },
        { field: 'Question', headerName:'Question', width: 150},

      ];

      //onRowClick={(rows)=> {navigate('/exam-question-creation', state: {rows.ExamQuestionID}) }}
    

    return(
        <div className="container" style={{paddingTop:125, paddingRight: 200, paddingLeft:200}}>
            <button>Add Another Question</button>
            <DataGrid getRowId={(row)=> row.ExamQuestionID} rows={questions} columns={columns} />
     </div>
    )
}