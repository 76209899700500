
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants";
export const fetchUser = createAsyncThunk(
  "users/fetchByEmail",
  async (certID, thunkAPI) => {
    console.log(certID, "data");
    const response = await axios
      .post(`${API_URL}/login`, certID)
      .then((response) => {
        let returnObj = response.data;
        return returnObj;
      })
      .catch((e) => {
        console.log(e, "error");
      });
    return response;
  }
);

export const checkExamCode = createAsyncThunk(
  "users/checkExamCode",
  async (code, thunkAPI) => {
    try {
      const response = await axios.post(`${API_URL}/checkexamcode`, {examCode: code});
      console.log('Response before getall: ',response);
      //const returnedExamResponse = await axios.get(`${API_URL}/getall/${response.data.ExamID}`);
      //console.log(returnedExamResponse.data);
      return response.data;
    } catch (e) {
      console.log(e, "error");
    }
  }
);

const initialState = {
  user: {},
  token: "",
  exams: {},
  error: null,
  examVerified: false,
  examLoading: false,
  loading: false,
  isLoggedIn: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = {};
      state.token = "";
      state.isLoggedIn = false;
      state.exams = {};
      state.examVerified = false;
    },
    clearExamVerified: (state) => {
      state.examVerified = false;
      state.exams = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkExamCode.pending, (state) => {
        state.examLoading = true;
        state.error = null;
      })
      .addCase(checkExamCode.fulfilled, (state, action) => {
        state.examLoading = false;
        state.examVerified = true;
        state.exams = action.payload;
      })
      .addCase(checkExamCode.rejected, (state, action) => {
        state.examLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.exams = action.payload.exams;
        state.examVerified = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },

  //   extraReducers: (builder) => {
  //     builder
  //     example of using the fetchUserById async thunk
  //       .addCase(fetchUserById.pending, (state, action) => {
  //         state.loading = true;
  //       })
  //       .addCase(fetchUserById.fulfilled, (state, action) => {
  //         state.loading = false;
  //         state.user = action.payload;
  //       })
  //       .addCase(fetchUserById.rejected, (state, action) => {
  //         state.loading = false;
  //         state.error = action.error.message;
  //       });
  //   },

});

// example of exporting a standard synchronous action
export const { logoutUser, clearExamVerified } = userSlice.actions;

export default userSlice.reducer;
