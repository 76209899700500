import React from "react"


export default function NotFound() {


    return(
        <div>
            Page Not Found
        </div>
    )
}