import React, { useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { PDFDocument } from "pdf-lib";
import { Document, Page } from "react-pdf";
import { PDFDownloadLink, pdf, StyleSheet, Image, Text } from "@react-pdf/renderer";
import {API_URL} from "./constants";
import axios from "axios";
import { pdfjs } from 'react-pdf';
import { create } from "@mui/material/styles/createTransitions";

import coitbbadge from "../src/Assets/coitbbadges/coitb-badge.png"
import { Style } from "@mui/icons-material";


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
    standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};


export default function CertGeneration(props){
    const location = useLocation();
    const [modifiedPDFBytes, setModifiedPDFBytes] = useState([]);
    const [createdPDF, setCreatedPDF] = useState();

    function download() {
        const blob = new Blob([modifiedPDFBytes], { type: "application/pdf;charset=utf-8" });
        console.log('Blob Obj: ',blob);
        var url = URL.createObjectURL(blob);
        window.open(url);
    }

    const styles = StyleSheet.create({
        Image: {
            width:200,
            height:200,
            
        },
    })

    useEffect(()=> {
        console.log('State Carried Over from Form: ', location.state);
        //get the certName from the db based on the certid
        async function fetchData(){
            //Fetch from erasolutions api and return the document itself instead of locally
            const bytes = await fetch(`${API_URL}/loadbasecert`).then(res => res.arrayBuffer());
            let UintBytes = new Uint8Array(bytes);
            const pdfDoc = await PDFDocument.load(UintBytes);
          
            if(pdfDoc){
                try{
                    // Get the form containing all the fields
                    const form = pdfDoc.getForm(); 
                    let fullname = `${location.state.FirstName} ${location.state.LastName}`;
                    let date = new Date(location.state.CertDate);

                    // Get the form Fields and set the values
                    const nameField = form.getTextField("Text1");
                    const dateField = form.getTextField("Text2");
                    const certField = form.getTextField("Text3");
                    const codeField = form.getTextField("Text4");
                    const certName = form.getTextField("Text5");

                    //set values for the form fields
                    nameField.setText(fullname);
                    dateField.setText(date.toLocaleDateString('en-US'));
        
                    certName.setText(String(location.state.Cert));
                    
                    certField.setText(String(location.state.CertID));
                    codeField.setText(String(location.state.Guid));
 
                    //certName.setFontSize(21);

                    //add img for CourseIconPath
                    //pdfDoc.embedPng(coitbbadge);
            
                    // Save the modified document as a new PDF file
                    const pdfBytes = await pdfDoc.save();
                    setModifiedPDFBytes( new Uint8Array(pdfBytes));
                    setCreatedPDF(await PDFDocument.load(modifiedPDFBytes));
                    console.log("Created PDF OBJ:", createdPDF);
                }
                catch(e){
                    console.log("PDF Doc Load Error: ", e);
                }
            }
            else
            {
                console.log("ERROR");
            }
        }

        fetchData();

    },[])

    return(
        <div className="DisplayBlock" style={{'width': '100%'}}>
            <button className="" onClick={download}>Download PDF</button>
            <Document options={options} file={{data: modifiedPDFBytes}} className="pdf" loading="Please wait for PDF to Generate" noData="The PDF has not been loaded correctly." onLoadError={(error) => alert(error.message)} renderMode="canvas">
                <Page pageNumber={1} renderAnnotationLayer={false}>
                   {coitbbadge}
                    {/* <Image style={styles.Image} src={coitbbadge}/> */}
                </Page>
            </Document>
        </div>
    )
}
