import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { TextField, Checkbox, Button, FormGroup, InputLabel, Grid, } from "@mui/material"; 
import {API_URL} from "../constants";

export default function FrontPage() {
    const navigate = useNavigate();
    //state variables for objects
    const [certUser, setCertUser] = useState({});
    const [morals, setMorals] = useState(false);

    //check cert number against number in db
    const checkCertValidity = (e) => {
        e.preventDefault();
        console.log('Cert User on Submit: ', certUser);
        if(morals){
            axios.post(`${API_URL}/addcertuser`, {certUser}).then(res=> {
                console.log(res.data);
                navigate('/examentry', {  state: {certUser}});
            }).catch(ex => {
                console.log(ex);
            })
        }
        else{
            alert('To continue, please agree to our moral standards');
        }
    }



    const handleFieldChange = (e) => {
        let name = e.target.name;
        setCertUser({
            ...certUser, 
            [name]: e.target.value
        });
    }

    //handle user that already has account
    //useEffect(()=> {
    //    const data = axios.get(`${API_URL}/getcertuser`).then(res=> res.data);
    //    if(data){
    //        setCertUser(data);
    //    }
    //},[])
    

    return(
        <div className="container" style={{paddingTop:125, paddingRight: 200, paddingLeft:200}}>
           <form onSubmit={checkCertValidity}>
           <Grid container spacing="2" paddingX={2}>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>First Name:</InputLabel>
                        <TextField name="FirstName" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter First Name"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Last Name:</InputLabel>
                        <TextField name="LastName" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Last Name"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Email:</InputLabel>
                        <TextField name="Email" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Email"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Password:</InputLabel>
                        <TextField type="password" name="Password" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Password"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Phone:</InputLabel>
                        <TextField name="Phone" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Phone"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Address:</InputLabel>
                        <TextField name="Address" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Address"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Address Line 1:</InputLabel>
                        <TextField name="AddressLine1" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Address Line 1"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>City:</InputLabel>
                        <TextField name="City" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter City"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>State:</InputLabel>
                        <TextField name="State" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter State"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Zip:</InputLabel>
                        <TextField name="Zip" onChange={e => handleFieldChange(e)} variant="outlined" placeholder="Enter Zip"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                        <InputLabel>By checking this box, I agree to uphold the ERA Solutions morals and values and not cheat in any way on this exam.  If I break this, I forfeit the rights to my firstborn.</InputLabel>
                        <Checkbox name="chkMorals" value={morals} onChange={e => setMorals(e.target.checked)} aria-label="By checking this box, I agree to uphold the ERA Solutions morals and values and not cheat in any way on this exam.  If I break this, I forfeit the rights to my firstborn."/> 
                    </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                        <Button type="submit" onClick={checkCertValidity}>Continue</Button>
                    </Grid>
            </Grid>
           </form>
        </div>
    )
}