import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import axios from "axios";
import { TextField, Checkbox, Button, FormGroup, InputLabel, Grid } from "@mui/material"; 
import {API_URL} from "../../constants";

export default function SectionCreation() {
    const navigate = useNavigate(); 
    const location = useLocation();
    //state variables for objects
    const [SectionName, setSectionName] = useState("");
    const [Score, setScore] = useState(0);
    const ExamID = location.state.ExamID;
   

    //check cert number against number in db
    const sectionCreation = (e) => {
        e.preventDefault();
        let data = [
            SectionName,
            Score, 
            ExamID
        ]
            axios.post(`${API_URL}/addsection`, {data}).then(res=> {
                console.log(res.data);
                navigate('/sections-list', {  state: {ExamID}});
            }).catch(ex => {
                console.log(ex);
            })
    }

    
    

    return(
        <div className="container" style={{paddingTop:125, paddingRight: 200, paddingLeft:200}}>
           <form onSubmit={sectionCreation}>
           <Grid container spacing="2" paddingX={2}>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Section Name:</InputLabel>
                        <TextField name="Name" onChange={e => setSectionName(e.target.value)} variant="outlined" placeholder="Enter Section Name"/>
                        </FormGroup>
                    </Grid>
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                    <FormGroup>
                    <InputLabel>Score:</InputLabel>
                        <TextField type="number" name="Score" onChange={e => setScore(e.target.value)} variant="outlined" placeholder="Enter Score"/>
                        </FormGroup>
                    </Grid>
                    
                    <Grid item lg={12} md={10} sm={10} paddingBottom={2}>
                        <Button type="submit" onClick={sectionCreation}>Continue</Button>
                    </Grid>
            </Grid>
           </form>
        </div>
    )
}